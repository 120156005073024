<template>
  <v-card flat class="ngo">
    <v-card-text>
      <router-view />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'index',
  metaInfo: {
    title: 'Ngo'
  }
};
</script>
