<template>
  <div class="ngo drop-offs">
    <h3 class="page-title">Drop off schedule</h3>
    <p>Select the location where you want the volunteers to drop off the bread on that day.</p>

    <v-row v-for="(schedule, index) in schedules" :key="index">
      <v-col cols="12">
        <div class="list-head mb-3">
          <div class="date">
            {{ $moment(schedule.date).format('ddd, D MMM YYYY') }}
          </div>
        </div>
        <drop-select :schedule="schedule" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import * as dropOffService from '@/services/dropoff';
import DropSelect from '@/components/ngo-drop-select';
export default {
  metaInfo: {
    title: 'Dropoffs'
  },
  components: {
    DropSelect
  },
  data: () => ({
    schedules: []
  }),
  created () {
    this.getSchedules();
  },
  methods: {
    getSchedules () {
      dropOffService.schedules()
        .then(response => {
          this.schedules = response;
        });
    }
  }
};
</script>

<style scoped>

</style>
