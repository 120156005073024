<template>
  <div class="select-backdrop">
    <span class="backdrop" v-if="backdrop" @click="backdrop = false"></span>
    <v-select
      v-model="schedule.dropoff.id"
      :items="$store.state.dropoffs.all"
      item-text="nameEn"
      item-value="id"
      class="light-primary-select label-left"
      flat
      solo
      :auto-select-first="true"
      background-color="#FCF1EA"
      :append-icon="'mdi-chevron-' + (backdrop ? 'up' : 'down')"
      @change="updateSchedule(schedule)"
      @mousedown="backdrop = !backdrop"
      :class="{'overlay': backdrop}"
      hide-details
      color="#0A3F60"
      :menu-props="{ bottom: true, offsetY: true, value: backdrop }"
    >
      <template slot="prepend-inner">
        Drop off
      </template>
    </v-select>
  </div>
</template>

<script>
import * as dropOffService from '@/services/dropoff';
export default {
  data: () => ({
    backdrop: false
  }),
  props: {
    schedule: {
      type: Object,
      required: true
    }
  },
  methods: {
    updateSchedule (schedule) {
      let params = {
        date: schedule.date,
        dropoff_id: schedule.dropoff.id,
        ngo_id: schedule.ngo.id
      };
      dropOffService.updateSchedule(params)
        .then(() => {
          this.backdrop = false;
        });
    }
  }
};
</script>
