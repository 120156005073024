<template>
  <div>
    <div class="select-backdrop">
      <span class="backdrop" v-if="backdrop" @click="backdrop = false"></span>
      <v-select
        v-model="sort"
        :items="sortsBy"
        item-text="title"
        item-value="value"
        class="light-secondary-select"
        solo
        background-color="#3E6B86"
        :append-icon="'mdi-chevron-' + (backdrop ? 'up' : 'down')"
        @change="setSortType"
        hide-details
        @mousedown="backdrop = !backdrop"
        :class="{'overlay': backdrop}"
        :menu-props="{ bottom: true, offsetY: true, value: backdrop }"
      >
        <template slot="label">
          Sort by
        </template>
        <template slot="prepend-inner">
          Sort by
        </template>
      </v-select>
    </div>
    <div class="volunteer-wrapper pt-4">
      <template v-if="volunteers.length">
        <v-card
          elevation="0"
          color="rgba(207,218,225,0.2)"
          class="volunteer-block mb-1"
          tile
          v-for="volunteer in sortedVolunteers"
          :key="volunteer.id"
        >
          <v-card-title class="px-5 pt-2 pb-0 justify-space-between">
            <div class="name"><strong>{{ volunteer.name }}</strong></div>
            <div class="runs">
              <strong>{{ volunteer.totalRunsCompleted }}</strong>&nbsp;
              <span>{{ volunteer.totalRunsCompleted > 1 ? 'runs' : 'run' }}</span>
            </div>
          </v-card-title>
          <v-card-subtitle class="px-5 pb-3 mt-0 d-flex justify-space-between">
            <div class="email">{{ volunteer.email }}</div>
            <div class="date">Since {{ $moment(volunteer.createdAt).format('D MMM YYYY') }}</div>
          </v-card-subtitle>
        </v-card>
      </template>
      <div v-else class="volunteer-empty px-5 text-center">No records</div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import * as volunteerService from '@/services/volunteer';
export default {
  name: 'volunteers',
  metaInfo: {
    title: 'Volunteers'
  },
  data () {
    return {
      sort: null,
      sortAsc: true,
      sortsBy: [
        {
          title: 'Name',
          value: 'name'
        },
        {
          title: 'Join date',
          value: 'createdAt'
        },
        {
          title: 'Number of runs',
          value: 'totalRunsCompleted'
        }
      ],
      volunteers: [],
      backdrop: false
    };
  },
  computed: {
    sortedVolunteers () {
      return _.orderBy(this.volunteers, this.sort, [this.sortAsc ? 'asc' : 'desc']);
    }
  },
  created () {
    this.getVolunteers();
  },
  methods: {
    getVolunteers () {
      volunteerService.get()
        .then(response => {
          this.volunteers = response;
        });
    },
    setSortType () {
      this.sortAsc = !this.sortAsc;
      this.backdrop = false;
    }
  }
};
</script>
