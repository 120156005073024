<template>
  <v-layout row wrap>
    <v-col cols="12">
      <div class="select-backdrop">
        <v-text-field
          class="light-secondary-select"
          solo
          background-color="#3E6B86"
          append-icon="mdi-chevron-down"
          @focus="dateDialog = true"
          hide-details
          readonly
        >
          <template slot="label">
            {{ formattedDate }}
          </template>
          <template slot="prepend-inner">
            Date
          </template>
        </v-text-field>

        <v-dialog
          ref="dialog"
          v-model="dateDialog"
          :return-value.sync="filters.date"
          title-date-format="ddd, D MMM YYYY"
          header-color="#FCF1EA"
          width="290px"
        >
          <v-date-picker
            event-color="secondary"
            color="#FBE8DC"
            v-model="date"
            scrollable
            selected-items-text="secondary"
            class="custom-date"
          >
            <v-spacer></v-spacer>
            <v-btn text color="brown" @click="dateDialog = false">Cancel</v-btn>
            <v-btn text color="brown" @click="$refs.dialog.save(date)">OK</v-btn>
          </v-date-picker>
        </v-dialog>
      </div>
    </v-col>
    <v-col cols="12">
      <div class="select-backdrop">
        <span class="backdrop" v-if="backdrop" @click="backdrop = false"></span>
        <v-select
          v-model="filters.status"
          :items="$store.state.runStatuses"
          item-text="title"
          class="light-secondary-select"
          solo
          :auto-select-first="true"
          background-color="#3E6B86"
          hide-details
          :append-icon="'mdi-chevron-' + (backdrop ? 'up' : 'down')"
          @change="backdrop = false"
          @mousedown="backdrop = !backdrop"
          :class="{'overlay': backdrop}"
          :menu-props="{ bottom: true, offsetY: true, value: backdrop }"
        >
          <template slot="label">
            All
          </template>
          <template slot="prepend-inner">
            Status
          </template>
        </v-select>
      </div>
    </v-col>
    <v-col cols="12" v-for="run in runs" :key="run.id">
      <div class="card-wrapper">
        <run-card :run="run" :light="true" />
      </div>
    </v-col>
  </v-layout>
</template>

<script>
import * as runService from '@/services/run';
import RunCard from '../../components/run-card';

export default {
  components: { RunCard },
  metaInfo: {
    title: 'Runs'
  },
  data () {
    return {
      dialog: false,
      dateDialog: false,
      selected: [],
      date: null,
      status: null,
      runs: [],
      filters: {
        date: null
      },
      backdrop: false
    };
  },
  computed: {
    formattedDate () {
      return this.$moment(this.filters.date).format('ddd, D MMM YYYY');
    },
    dates () {
      const dateArr = [];
      const today = new Date();
      for (let i = 0; i < 4; i++) {
        let nextDay = today.setDate(new Date().getDate() + i);
        dateArr.push({
          text: i === 0 ? 'Today' : this.$moment(nextDay).format('ddd, D MMM'),
          value: this.$moment(nextDay).format('YYYY-MM-D')
        });
      }
      return dateArr;
    }
  },
  created () {
    this.date = this.filters.date = this.$moment().format('YYYY-MM-DD');
  },
  methods: {
    getRuns () {
      runService.get(this.filters)
        .then(response => {
          this.runs = response;
        });
    }
  },
  watch: {
    filters: {
      handler () {
        this.getRuns();
        this.date = this.$moment(this.filters.date).format('YYYYMMDD');
      },
      deep: true
    }
  }
};
</script>
